define("discourse/plugins/discourse-blizzard-plugin/discourse/components/category-field-name", ["exports", "discourse/components/text-field", "discourse-common/utils/decorators", "I18n"], function (_exports, _textField, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
    Same as text-field, but adding the placeholder text as title
  **/
  var _default = _exports.default = _textField.default.extend(dt7948.p({
    title(titleKey) {
      return titleKey ? _I18n.default.t(titleKey) : "";
    }
  }, [["method", "title", [(0, _decorators.default)("titleKey")]]]));
});