define("discourse/plugins/discourse-blizzard-plugin/discourse/components/b-post-statuses", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="b-post-statuses">
    {{#each this.postStatuses as |status|}}
      <div class="b-post-status b-post-status-{{status}}">
        {{i18n (concat "post_statuses." status)}}
      </div>
    {{/each}}
  </div>
  */
  {
    "id": "OaA1VxMe",
    "block": "[[[10,0],[14,0,\"b-post-statuses\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"postStatuses\"]]],null]],null],null,[[[1,\"    \"],[10,0],[15,0,[29,[\"b-post-status b-post-status-\",[30,1]]]],[12],[1,\"\\n      \"],[1,[28,[35,2],[[28,[37,3],[\"post_statuses.\",[30,1]],null]],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]],null],[13]],[\"status\"],false,[\"each\",\"-track-array\",\"i18n\",\"concat\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/components/b-post-statuses.hbs",
    "isStrictMode": false
  });
  class BPostStatuses extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    get postStatuses() {
      if (this.currentUser && this.currentUser.staff) {
        let postStatuses = [];
        if (this.args.postMetadata.hidden) {
          postStatuses.push("hidden");
        }
        if (this.args.postMetadata.deleted) {
          postStatuses.push("deleted");
        }
        let as = this.args.postMetadata.actionsSummary;
        if (as && as.some(i => i.action === "spam")) {
          postStatuses.push("spam");
        }
        return postStatuses;
      }
      return [];
    }
  }
  _exports.default = BPostStatuses;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BPostStatuses);
});