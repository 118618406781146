define("discourse/plugins/discourse-blizzard-plugin/initializers/blizzard-franchise", ["exports", "discourse/plugins/discourse-blizzard-plugin/lib/blizzard-categories"], function (_exports, _blizzardCategories) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "blizzard-franchise",
    after: "inject-objects",
    initialize(container) {
      let site = container.lookup("service:site");
      if (site.category_metadata) {
        Object.keys(site.category_metadata).forEach(categoryId => {
          (0, _blizzardCategories.addMetaData)(categoryId, site.category_metadata[categoryId]);
        });
      }
    }
  };
});