define("discourse/plugins/discourse-blizzard-plugin/discourse/components/b-categories-columned", ["exports", "@ember/component", "@ember/service", "@ember/template-factory"], function (_exports, _component, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="b-categories-columned">
    {{#each this.columnedCategories as |c|}}
      {{#if this.displayMobileSubcategories}}
        <BMobileSubcategories @category={{c}} />
      {{else}}
        <BCategory @category={{c}} />
      {{/if}}
    {{/each}}
  </div>
  */
  {
    "id": "sEpByh9C",
    "block": "[[[10,0],[14,0,\"b-categories-columned\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"columnedCategories\"]]],null]],null],null,[[[41,[30,0,[\"displayMobileSubcategories\"]],[[[1,\"      \"],[8,[39,3],null,[[\"@category\"],[[30,1]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,4],null,[[\"@category\"],[[30,1]]],null],[1,\"\\n\"]],[]]]],[1]],null],[13]],[\"c\"],false,[\"each\",\"-track-array\",\"if\",\"b-mobile-subcategories\",\"b-category\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/components/b-categories-columned.hbs",
    "isStrictMode": false
  });
  class BCategoriesColumned extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    get columnedCategories() {
      if (this.siteSettings.blizzard_realms_enabled) {
        return this.categories.filter(c => !c.get("category_metadata.is_realm"));
      }
      return this.categories;
    }
    get displayMobileSubcategories() {
      // If any of our categories has subcategories and we're on mobile, use the alternative display
      if (this.site.mobileView) {
        let hasSubcategories = this.site.get("categories").some(c => {
          return (c.get("subcategories.length") || 0) >= 1;
        });
        if (hasSubcategories) {
          return true;
        }
      }
      return false;
    }
  }
  _exports.default = BCategoriesColumned;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BCategoriesColumned);
});