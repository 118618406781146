define("discourse/plugins/discourse-blizzard-plugin/discourse/components/modal/anonymous-report", ["exports", "@ember/component", "@glimmer/tracking", "@ember/object", "discourse-common/lib/get-url", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _getUrl, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.show}}
    <DModal
      @class="flag-modal-body"
      @title={{i18n "flagging.title"}}
      @closeModal={{fn (mut this.show) false}}
      class="anonymous-report-modal"
    >
      <:body>
        <BFlagReport @post={{@model.flagModel}} @disableClick="true" />
      </:body>
      <:footer>
        <DButton
          class="btn-primary flag-modal__create-flag"
          @action={{this.navigate}}
          @title="flagging.submit_tooltip"
          @icon="flag"
          @label="flagging_topic.action"
        />
      </:footer>
    </DModal>
  {{/if}}
  */
  {
    "id": "N/w22umI",
    "block": "[[[41,[30,0,[\"show\"]],[[[1,\"  \"],[8,[39,1],[[24,0,\"anonymous-report-modal\"]],[[\"@class\",\"@title\",\"@closeModal\"],[\"flag-modal-body\",[28,[37,2],[\"flagging.title\"],null],[28,[37,3],[[28,[37,4],[[30,0,[\"show\"]]],null],false],null]]],[[\"body\",\"footer\"],[[[[1,\"\\n      \"],[8,[39,5],null,[[\"@post\",\"@disableClick\"],[[30,1,[\"flagModel\"]],\"true\"]],null],[1,\"\\n    \"]],[]],[[[1,\"\\n      \"],[8,[39,6],[[24,0,\"btn-primary flag-modal__create-flag\"]],[[\"@action\",\"@title\",\"@icon\",\"@label\"],[[30,0,[\"navigate\"]],\"flagging.submit_tooltip\",\"flag\",\"flagging_topic.action\"]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@model\"],false,[\"if\",\"d-modal\",\"i18n\",\"fn\",\"mut\",\"b-flag-report\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/components/modal/anonymous-report.hbs",
    "isStrictMode": false
  });
  class AnonymousReport extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "show", [_tracking.tracked], function () {
      return true;
    }))();
    #show = (() => (dt7948.i(this, "show"), void 0))();
    get href() {
      return (0, _getUrl.getAbsoluteURL)(`/dsa/report.json?post_id=${this.model.flagModel.id}`);
    }
    navigate(e) {
      window.open(this.href, "_blank", "noopener=true");
      this.show = false;
      e.preventDefault();
    }
    static #_2 = (() => dt7948.n(this.prototype, "navigate", [_object.action]))();
  }
  _exports.default = AnonymousReport;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AnonymousReport);
});