define("discourse/plugins/discourse-blizzard-plugin/discourse/components/b-realms-list", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.filteredRealms}}
    <div class="b-realms-list">
      {{! template-lint-disable no-invalid-interactive }}
      <div class="realms-title" {{on "click" this.toggleExpanded}}>
        <div class="realms-icon"></div>
        <div class="realms-title-text">{{i18n (concat "realms." @filter)}}</div>
        {{d-icon this.caretIcon class="caret-icon"}}
      </div>
  
      {{#if this.isExpanded}}
        <div class="filtered-realms {{@filter}}">
          {{#each this.filteredRealms as |r|}}
            <div class="filtered-realm">
              <a class="b-realm" href={{r.url}}>
                <div class="realm-icon"></div>
                <div class="realm-name">{{realm-name r}}</div>
              </a>
            </div>
          {{/each}}
        </div>
      {{/if}}
    </div>
  {{/if}}
  */
  {
    "id": "/i43ysmc",
    "block": "[[[41,[30,0,[\"filteredRealms\"]],[[[1,\"  \"],[10,0],[14,0,\"b-realms-list\"],[12],[1,\"\\n\"],[1,\"    \"],[11,0],[24,0,\"realms-title\"],[4,[38,1],[\"click\",[30,0,[\"toggleExpanded\"]]],null],[12],[1,\"\\n      \"],[10,0],[14,0,\"realms-icon\"],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"realms-title-text\"],[12],[1,[28,[35,2],[[28,[37,3],[\"realms.\",[30,1]],null]],null]],[13],[1,\"\\n      \"],[1,[28,[35,4],[[30,0,[\"caretIcon\"]]],[[\"class\"],[\"caret-icon\"]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"isExpanded\"]],[[[1,\"      \"],[10,0],[15,0,[29,[\"filtered-realms \",[30,1]]]],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,0,[\"filteredRealms\"]]],null]],null],null,[[[1,\"          \"],[10,0],[14,0,\"filtered-realm\"],[12],[1,\"\\n            \"],[10,3],[14,0,\"b-realm\"],[15,6,[30,2,[\"url\"]]],[12],[1,\"\\n              \"],[10,0],[14,0,\"realm-icon\"],[12],[13],[1,\"\\n              \"],[10,0],[14,0,\"realm-name\"],[12],[1,[28,[35,7],[[30,2]],null]],[13],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[2]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"@filter\",\"r\"],false,[\"if\",\"on\",\"i18n\",\"concat\",\"d-icon\",\"each\",\"-track-array\",\"realm-name\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/components/b-realms-list.hbs",
    "isStrictMode": false
  });
  class BRealmsList extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "isExpanded", [_tracking.tracked], function () {
      return true;
    }))();
    #isExpanded = (() => (dt7948.i(this, "isExpanded"), void 0))();
    get caretIcon() {
      return this.isExpanded ? "caret-up" : "caret-down";
    }
    get filteredRealms() {
      let list = this.args.categories.filter(c => c.get("category_metadata.is_realm"));
      if (this.args.filter === "classic") {
        list = list.filter(c => c.get("category_metadata.is_realm") === "classic");
      } else if (this.args.filter === "yours") {
        // TODO: how to get current user in glimmer component
        let yours = this.currentUser && this.currentUser.get("realms_list") || [];
        list = list.filter(c => yours.indexOf(c.get("name")) !== -1);
      } else {
        list = list.filter(c => c.get("category_metadata.is_realm") !== "classic");
      }
      return list.sortBy("name");
    }
    toggleExpanded() {
      this.isExpanded = !this.isExpanded;
    }
    static #_3 = (() => dt7948.n(this.prototype, "toggleExpanded", [_object.action]))();
  }
  _exports.default = BRealmsList;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BRealmsList);
});