define("discourse/plugins/discourse-blizzard-plugin/discourse/components/b-welcome", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "I18n", "@ember/template-factory"], function (_exports, _component, _component2, _service, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <header class="b-welcome {{@welcome-location}}">
    {{#if this.viewingHomepage}}
      <div class="b-welcome-banner-wrap">
        <DSection @bodyClass="blizzard-homepage" class="b-welcome-banner">
          <div
            class="b-welcome-banner-contents wrap
              {{unless this.welcomeText 'no-welcome-text'}}"
          >
            <div class="game-logo"></div>
  
            {{#if this.welcomeText}}
              <h1>{{this.welcomeText}}</h1>
            {{/if}}
          </div>
        </DSection>
      </div>
    {{/if}}
  </header>
  */
  {
    "id": "w6M0LVYa",
    "block": "[[[10,\"header\"],[15,0,[29,[\"b-welcome \",[30,1]]]],[12],[1,\"\\n\"],[41,[30,0,[\"viewingHomepage\"]],[[[1,\"    \"],[10,0],[14,0,\"b-welcome-banner-wrap\"],[12],[1,\"\\n      \"],[8,[39,1],[[24,0,\"b-welcome-banner\"]],[[\"@bodyClass\"],[\"blizzard-homepage\"]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[15,0,[29,[\"b-welcome-banner-contents wrap\\n            \",[52,[51,[30,0,[\"welcomeText\"]]],\"no-welcome-text\"]]]],[12],[1,\"\\n          \"],[10,0],[14,0,\"game-logo\"],[12],[13],[1,\"\\n\\n\"],[41,[30,0,[\"welcomeText\"]],[[[1,\"            \"],[10,\"h1\"],[12],[1,[30,0,[\"welcomeText\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"@welcome-location\"],false,[\"if\",\"d-section\",\"unless\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/components/b-welcome.hbs",
    "isStrictMode": false
  });
  class BWelcome extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    get viewingHomepage() {
      return this.args.currentPath === "discovery.categories";
    }
    get welcomeText() {
      let franchise = this.site.get("blizzard_franchise");
      return _I18n.default.t(`${franchise}.welcome`, {
        defaultValue: ""
      });
    }
  }
  _exports.default = BWelcome;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BWelcome);
});