define("discourse/plugins/discourse-blizzard-plugin/discourse/components/b-category-groups", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "discourse/plugins/discourse-blizzard-plugin/lib/blizzard-categories", "@ember/template-factory"], function (_exports, _component, _component2, _service, _blizzardCategories, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each this.categoryGroups as |cg|}}
    <div class="category-group-name">{{cg.name}}</div>
    <div class="b-category-container">
      {{#each cg.categories as |c|}}
        <BCategory @category={{c}} />
      {{/each}}
    </div>
  {{/each}}
  */
  {
    "id": "IVtLAN+T",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"categoryGroups\"]]],null]],null],null,[[[1,\"  \"],[10,0],[14,0,\"category-group-name\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n  \"],[10,0],[14,0,\"b-category-container\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1,[\"categories\"]]],null]],null],null,[[[1,\"      \"],[8,[39,2],null,[[\"@category\"],[[30,2]]],null],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"]],[1]],null]],[\"cg\",\"c\"],false,[\"each\",\"-track-array\",\"b-category\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/components/b-category-groups.hbs",
    "isStrictMode": false
  });
  class BCategoryGroups extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    get categoryGroups() {
      return (0, _blizzardCategories.groupCategories)(this.args.categories, {
        site: this.site,
        siteSettings: this.siteSettings
      });
    }
  }
  _exports.default = BCategoryGroups;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BCategoryGroups);
});