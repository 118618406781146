define("discourse/plugins/discourse-blizzard-plugin/initializers/character-select", ["exports", "discourse/components/modal/avatar-selector", "discourse/plugins/discourse-blizzard-plugin/discourse/components/modal/character-selector"], function (_exports, _avatarSelector, _characterSelector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "character-select",
    initialize(container) {
      const appEvents = container.lookup("service:app-events");
      const modal = container.lookup("service:modal");
      appEvents.on("show-character-select", user => {
        modal.show(_characterSelector.default, {
          model: {
            user
          }
        });
      });
      appEvents.on("show-avatar-select", user => {
        modal.show(_avatarSelector.default, {
          model: {
            user
          }
        });
      });
    }
  };
});