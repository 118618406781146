define("discourse/plugins/discourse-blizzard-plugin/discourse/raw-templates/connectors/topic-list-after-main-link/creator-column", ["exports", "discourse-common/lib/raw-handlebars", "discourse-common/lib/raw-templates"], function (_exports, _rawHandlebars, _rawTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let template = (0, _rawHandlebars.template)({
    "1": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "      " + container.escapeExpression(lookupProperty(helpers, "get").call(depth0 != null ? depth0 : container.nullContext || {}, "context.topic.creator.name", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 4,
            "column": 6
          },
          "end": {
            "line": 4,
            "column": 36
          }
        }
      })) + "\n";
    },
    "3": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "      " + container.escapeExpression((lookupProperty(helpers, "format-username") || depth0 && lookupProperty(depth0, "format-username") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, "context.topic.creator.username", {
        "name": "format-username",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 6,
            "column": 6
          },
          "end": {
            "line": 6,
            "column": 56
          }
        }
      })) + "\n";
    },
    "compiler": [8, ">= 4.3.0"],
    "main": function (container, depth0, helpers, partials, data) {
      var stack1,
        alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "<div class=\"creator\">\n  <a href=\"" + alias2(lookupProperty(helpers, "get").call(alias1, "context.topic.creator.path", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 2,
            "column": 11
          },
          "end": {
            "line": 2,
            "column": 41
          }
        }
      })) + "\" data-user-card=\"" + alias2(lookupProperty(helpers, "get").call(alias1, "context.topic.creator.username", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 2,
            "column": 59
          },
          "end": {
            "line": 2,
            "column": 93
          }
        }
      })) + "\">\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "context.topic.creator.name", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(1, data, 0),
        "inverse": container.program(3, data, 0),
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 3,
            "column": 4
          },
          "end": {
            "line": 7,
            "column": 11
          }
        }
      })) != null ? stack1 : "") + "  </a>\n</div>\n";
    },
    "useData": true
  });
  (0, _rawTemplates.addRawTemplate)("javascripts/connectors/topic-list-after-main-link/creator-column", template, {
    core: true
  });
  var _default = _exports.default = template;
});